/*
 * Copyright 2024 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "vals";

.section-heading {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.section-subheading {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.section-note {
  display: inline-block;
  font-size: 12px;
  margin: 5px 0 20px;

  .attention {
    color: $go-danger;
  }
}

.user-input {
  font-size: 16px;
  margin-right: 20px;
  flex: 1 0 0;

  &.constrained {
    max-width: calc(50% - #{$fillable-section-margin});
  }

  input,
  select {
    font-size: inherit;
  }

  input {
    &[type="text"] {
      padding: 5px;
      width: 25vw;
      max-width: 350px;
    }

    &[type="url"] {
      width: 35vw;
      max-width: 500px;
    }
  }
}
