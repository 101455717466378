/*
 * Copyright 2024 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";
@import "../forms/forms";
@import "../forms/autocomplete";

.warning-wrapper {
  &:hover {
    .warning-content {
      display: block;
    }
  }
}

.warning-content {
  display: none;
  background: $white;
  line-height: 18px;
  font-size: 12px;
  position: absolute;
  margin-left: 72px;
  font-weight: 400;
  border-radius: $global-border-radius;
  padding: 10px;
  box-shadow: 0 2px 4px $box-shadow-color;
  text-transform: none;
  z-index: map_get($zindex, tooltip);
  width: 250px;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 0;
    left: 17px;
    box-sizing: border-box;
    border: 6px solid $black;
    border-color: $white $white transparent transparent;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: 1px -1px 1px 0 $box-shadow-color;
  }
}

.info-icon {
  @include icon-before($type: $fa-var-info-circle, $color: $black);

  &::before {
    padding: 2px;
    font-size: 15px;
  }
}

.icon-delete {
  border: none;
  display: inline;
  cursor: pointer;
  width: 22px;
  height: 22px;
  padding: 0;

  @include icon-before($type: $fa-var-xmark);

  &::before {
    margin: 0;
  }

  &:focus {
    outline: none;
  }
}

.add-rule {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 0;
}
