/*
 * Copyright 2024 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

.maintenance-mode-widget {
  background: $white;
  border: 1px solid $border-color;
  border-radius: 5px;
  padding: 30px;
  position: relative;
  top: 15px;
}

.maintenance-mode-description {
  font-size: 1rem;
  margin-bottom: 15px;
}

.maintenance-mode-info {
  display: block;
  padding: 10px 20px;
  border: 1px solid $border-color;
  border-radius: $global-border-radius;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: $screen-xxl) {
    width: 55%;
  }
}

.running-system {
  display: flex;
  font-size: 16px;
  margin-bottom: 7px;
  align-items: center;
}

.running-system-text {
  font-size: 14px;
  padding-left: 5px;
}

.updated-by {
  font-size: 13px;
  border-radius: 5px 5px 0 0;
  font-style: italic;
  color: $footer-fg;
  display: block;
  border-bottom: 1px solid $border-color;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.switch-wrapper {
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.maintenance-mode-label {
  font-size: 20px;
  font-weight: 600;
  margin-right: 13px;
  display: inline-block;
}

.maintenance-mode-state-message {
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  color: lighten($text-color, 40%);
}

.in-progress-subsystems {
  margin: 10px 0;
}

.running-sub-system {
  margin: 20px 0;
  padding: 0;
}

.gocd-sub-system-info {
  padding: 0;

  li {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.panel {
  border-radius: 3px;
  border: 1px solid $border-color;
  margin: 20px 0;
}

.panel-header {
  border-bottom: 1px solid $border-color;
  padding: 10px 20px;
  margin: 0;
}

.panel-body {
  padding: 10px 20px;
}

.running-system-header {
  padding: 20px 0 10px 0;
  margin: 0;
}

.scheduled-stages-title-wrapper {
  display: flex;
  align-items: center;
}

.scheduled-stages-title {
  margin-right: 7px;
}
