/*
 * Copyright 2024 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../../../global/common";

.main-container {
  width: 70%;
}

.tab-container {
  display: flex;
  width: 550px;
}

.tab-container + div {
  margin-right: 50px;
}

.tabs-header {
  width: 530px;
  margin: 20px 0 20px 0;
  font-size: 16px;

  span {
    margin-right: 150px;
  }
}
