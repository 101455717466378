/*
 * Copyright 2024 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../../../global/common";

.built-in-artifact-container {
  display: flex;
  padding: 0 0 10px 0;

  :nth-child(n) {
    margin-right: 20px;
  }
}

.artifact-container {
  margin-bottom: 30px;
  border-bottom: 1px solid $border-color;
}

.artifact-type {
  width: 220px;
  font-weight: 600;
  font-size: 15px;
}

.built-in-artifact-header {
  margin: 20px 0 20px 0;
  font-size: 16px;

  span:nth-child(1) {
    margin-right: 175px;
  }

  span {
    margin-right: 205px;
  }

  .id-header {
    margin-right: 245px;
  }
}

.add-artifact-wrapper {
  display: flex;
  width: 200px;
  justify-content: space-between;

  select {
    width: 100px;
  }

  button {
    margin-top: 4px;
  }
}

.plugin-view {
  width: 275px;
  margin: 0 0 0 240px;
}
