/*
 * Copyright 2024 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$key-value-pair-width: 200px;
$title-element-width: 360px;

.key-value-pair {
  padding: 0;
  margin: 0 0 $global-margin-bottom 0;
  list-style-type: none;

  :last-child {
    width: auto;
  }
}

.key {
  font-size: 13px;
  font-weight: 400;
  margin: 0 10px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  @media (max-width: $screen-md-min) {
    &::after {
      content: ":";
      margin-left: 10px;
      font-weight: 600;
    }
  }

  @media (min-width: $screen-md) {
    width: $key-value-pair-width;
    flex: 0 0 $key-value-pair-width;
  }
}

.value {
  font-size: 13px;
  font-weight: 600;
  position: relative;

  @media (min-width: $screen-md) {
    padding-left: 15px;
    margin-left: 0;

    &::after {
      content: ":";
      position: absolute;
      top: 0;
      left: 5px;
      font-weight: 600;
    }
  }

  pre {
    margin: 0;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
  }
}

.key-value-item {
  display: flex;
  padding: 5px 5px 5px 0;
  border-bottom: 1px dashed $border-color;
  align-content: center;
  flex-direction: column;

  @media (min-width: $screen-md) {
    flex-flow: row nowrap;
  }

  &:last-child {
    border: none;
  }
}

// inline key value pair
.key-value-pair-inline {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.key-value-inline-item {
  margin-right: 20px;
  border: none;
  padding: 0;
  flex-direction: row;

  .key {
    width: auto;

    @media (min-width: $screen-md) {
      flex: 0 0 auto;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 13px;
  margin: 0 50px 0 0;

  @media (min-width: $screen-md) {
    font-size: 12px;
  }

  @media (min-width: $screen-lg) {
    font-size: 15px;
    width: $title-element-width;
  }
}

.title-inline {
  display: inline;
  margin-left: 5px;
}
